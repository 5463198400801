/* =================================
------------------------------------
  WaliSons - FlourMill
  Version: 1.0
 ------------------------------------ 
 ====================================*/

/*----------------------------------------*/
/* Template default CSS
/*----------------------------------------*/
* {
	margin: 0;
	padding: 0;
	list-style: none;
	text-decoration: none;
}
html {
	scroll-behavior: smooth;
}
html,
body {
	height: 100%;
	font-family: 'Roboto', sans-serif;
}

a {
	display: inline-block;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: #222222;
	margin: 0;
	margin-bottom: 10px;
	font-weight: 700;
}

h1 {
	font-size: 60px;
}
span.bgColor {
	background: #39b54a;
	padding: 0 10px;
	color: #222222;
	display: inline-block;
}

h2 {
	font-size: 36px;
}

h3 {
	font-size: 24px;
	margin-bottom: 30px;
}

h4 {
	font-size: 15px;
}

p {
	font-size: 15px;
	color: #727272;
	line-height: 2;
}

img {
	max-width: 100%;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
	outline: none;
}

a:hover,
a:focus {
	text-decoration: none;
	outline: none;
}

ul,
ol {
	padding: 0;
	margin: 0;
}

/*------------------------
  Helper css
--------------------------*/
.sp-title {
	font-size: 60px;
	margin-bottom: 40px;
}

.sp-title span {
	background: #39b54a;
	padding: 0 10px;
	color: #222222;
	display: inline-block;
}

.pt100 {
	padding-top: 100px;
}

.pb100 {
	padding-bottom: 100px;
}

.pt50 {
	padding-top: 50px;
}

.pb50 {
	padding-bottom: 50px;
}

.mb100 {
	margin-bottom: 100px;
}

.spad {
	padding: 100px 0;
}

.section-title {
	margin-bottom: 75px;
}

.section-title h2 {
	display: inline-block;
	padding: 0 20px;
	margin-bottom: 0;
	font-size: 60px;
}
.section-title h2 span {
	background: #39b54a;
}

.section-title h1 {
	display: inline-block;
	background: #39b54a;
	padding: 0 20px;
	margin-bottom: 0;
	font-size: 60px;
}

.set-bg {
	background-size: cover;
	background-repeat: no-repeat;
}

/*------------------------
  Common element css
--------------------------*/

.site-btn {
	background: none;
	color: white;
	width: 200px;
	height: 50px;
	border: 3px solid white;
	transition: 0.3s;
	overflow: hidden;
	z-index: 10;
	font-size: 1.4rem;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}
.site-btn::before {
	content: '';
	display: block;
	position: absolute;
	background: white;
	width: 60px;
	height: 100%;
	left: 0;
	top: 0;
	opacity: 0.5;
	filter: blur(30px);
	transform: translateX(-130px) skewX(-15deg);
}
.site-btn::after {
	content: '';
	display: block;
	position: absolute;
	background: rgba(255, 255, 255, 0.2);
	width: 30px;
	height: 100%;
	left: 30px;
	top: 0;
	opacity: 0.5;
	filter: blur(30px);
	transform: translate(-100px) skewX(-15deg);
}
.site-btn:hover {
	color: white !important;
	background: #39b54a;
	cursor: pointer;
}
.site-btn:hover::before {
	transform: translateX(300px) skewX(-15deg);
	opacity: 0.6;
	transition: 0.7s;
}
.site-btn:hover::after {
	transform: translateX(300px) skewX(-15deg);
	opacity: 1;
	transition: 0.7s;
}

/*----------------------------------------*/
/*  Header CSS
/*----------------------------------------*/
.logo-area {
	width: 300px;
	height: 100%;
	background: #121212;
	background-color: transparent;
	display: flex !important;
	align-items: center;
	justify-content: center;
}
.logo-area img {
	width: 80%;
	height: 90%;
}
.navbar {
	height: 95px;
	background-color: #121212;
}

.nav-link {
	width: fit-content !important;
	margin-right: 40px;
	font-size: 0.9rem;
	color: white !important;
	text-transform: uppercase;
	position: relative;
}
.nav-link::before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0%;
	height: 2px;
	background-color: #39b54a;
	transition: all 0.4s ease-in-out;
}
.nav-item:hover .nav-link::before {
	width: 100%;
}
.dropdown-toggle::after {
	transition: transform 0.15s linear;
}

.show.dropdown .dropdown-toggle::after {
	transform: translateY(3px);
}
.dropdown-item {
	transition: 0.3s;
}
.dropdown-item:hover {
	background-color: #39b54a !important;
	padding-left: 30px;
}
.dropdown-menu {
	padding: 15px 0px;
}
.sub-dropdown-item {
	position: relative;
}

.sub-dropdown-item:hover .dropdown-toggle::after {
	transition: transform 0.15s linear;
}

.sub-dropdown-item:hover .dropdown-toggle::after {
	transform: rotate(-90deg);
}
.sub_dropdown-menu {
	position: absolute;
	left: 90%;
	top: 0;
	padding: 10px 0px;
	background-color: white;
	display: none;
}
.sub_dropdown-menu.show {
	display: block;
}
.sub-dropdown-item:hover .sub_dropdown-menu {
	display: block;
}

/*---------------------------------------*/
/*  Hero Section CSS
/*----------------------------------------*/
.hero-section {
	height: 800px;
	background: #ededed;
	position: relative;

	/* background-image: url('../assets/bg.jpg'); */
}

.hero-section .overlay-div {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 2;
}

.hero-section video {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
	object-fit: cover;
	background-color: black;
	background-color: rgba(0, 0, 0, 0.5);
}
.left-bar {
	position: absolute;
	width: 100px;
	height: 100%;
	background-color: transparent;
	z-index: 20;
}

.left-bar .left-bar-content {
	position: absolute;
	width: 100%;
	bottom: 0;
	text-align: center;
	margin-bottom: 30px;
}

.social-links li {
	display: flex;
	justify-content: center;
}
.social-links a {
	border-radius: 50%;
	display: block;
	color: #e2e2e2;
	margin-bottom: 20px;
	font-size: 20px;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	width: 40px;
	height: 40px;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.2),
		2px 2px 5px rgba(0, 0, 0, 0.2);
	transition: 0.5s;
	position: relative;
}
.social-links a .fa {
	color: white;
	position: relative;
	z-index: 1;
}

.social-links a:hover {
	color: #39b54a;
	transform: translateY(-10px);
	box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.2),
		2px 24px 10px rgba(0, 0, 0, 0.1);
}
.social-links a::before {
	content: '';
	position: absolute;
	left: 3px;
	right: 3px;
	top: 3px;
	bottom: 3px;
	border-radius: 50%;
	border: 2px solid rgba(0, 0, 0, 0.1);
}
.social-links li:nth-child(1) a::before {
	background-color: #25d366;
}
.social-links li:nth-child(2) a::before {
	background-color: #1877f2;
}
.social-links li:nth-child(3) a::before {
	background-color: #00acee;
}
.social-links li:nth-child(4) a::before {
	background-color: #e60023;
}
.hero-right-text {
	position: absolute;
	right: 50px;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	-webkit-transform-origin: right center;
	-ms-transform-origin: right center;
	transform-origin: right center;
	bottom: 60%;
	z-index: 30;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 20px;
}

.hero-slider .hero-slide-item {
	width: 100%;
	height: 900px;
	display: table;
	z-index: 2;
	position: absolute;
}

.hero-slider .hero-slide-item .slide-inner {
	display: table-cell;
	vertical-align: middle;
	position: relative;
}

.slide-content {
	margin-left: 200px;
	margin-bottom: 50px;
	padding-left: 190px;
	padding-top: 170px;
	padding-bottom: 70px;
	position: relative;
	-webkit-transition: all 0.6s;
	-o-transition: all 0.6s;
	transition: all 0.6s;
}

.slide-content:after {
	position: absolute;
	content: '';
	height: calc(100% + 50px);
	width: 330px;
	border-top: 150px solid #39b54a;
	border-left: 150px solid #39b54a;
	border-bottom: 90px solid #39b54a;
	top: 0;
	left: 0;
}

.slide-content h2 {
	color: #fff;
	font-size: 80px;
	line-height: 80px;
}
/*  */

/*----------------------------------------*/
/*  Intro Section CSS
/*----------------------------------------*/

/*----------------------------------------*/
/*  Service Section CSS
/*----------------------------------------*/
.service-box {
	margin-bottom: 30px;
}

.service-box .sb-icon {
	margin-bottom: 30px;
	width: 100px;
	height: 125px;
	text-align: center;
	position: relative;
	overflow: hidden;
	background-color: transparent;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
}

.service-box .sb-icon .sb-img-icon {
	position: absolute;
	left: 0;
	bottom: 0;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.service-box .sb-icon .sb-img-icon img {
	max-height: 70px;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	color: white;
}
.service-box h3 {
	color: white;
}
.service-box .sb-icon::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background: #fff;
	left: 25px;
	top: -80px;
	-webkit-transform: rotate(-65deg);
	-ms-transform: rotate(-65deg);
	transform: rotate(-65deg);
}

.service-box .readmore {
	font-size: 12px;
	font-weight: 700;
	color: white;
	display: inline-block;
	padding: 2px 0;
	background-color: transparent;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
}

.service-box:hover .sb-icon .sb-img-icon {
	left: 15px;
	margin-bottom: 5px;
}

.service-box:hover .sb-icon .sb-img-icon img {
	opacity: 1;
}

.service-box:hover .readmore {
	background-color: #39b54a;
	padding: 2px 10px;
}
@media (max-width: 500px) {
	.service-box {
		padding: 0 20px;
	}
	.slide-content {
		margin-left: 0 !important;
	}
}

/*----------------------------------------*/
/*  CTA Section CSS
/*----------------------------------------*/
.cta-section {
	position: relative;
	margin-bottom: 100px;
}

.cta-section:after {
	content: '';
	position: absolute;
	width: 67%;
	height: 100%;
	right: 0;
	top: 0;
	background: #222222;
	z-index: 2;
}

.cta-section .cta-image-box {
	position: absolute;
	width: 50%;
	height: 100%;
	left: 0;
	background-image: url('../assets/crs.webp');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.cta-section .container {
	position: relative;
	z-index: 9;
}

.cta-section .cta-content h2 {
	color: #fff;
}

.cta-section .cta-content p {
	color: #fff;
	margin-bottom: 30px;
}

.cta-section .cta-content .cta-img-icon {
	display: inline-block;
	height: 80px;
	position: relative;
	width: 50px;
	margin-right: 40px;
	margin-bottom: 30px;
}

.cta-section .cta-content .cta-img-icon:last-child {
	margin-right: 0;
}

.cta-section .cta-content .cta-img-icon img {
	position: absolute;
	left: 0;
	bottom: 0;
	max-height: 100%;
}

/*----------------------------------------*/
/* Letter Section CSS
/*----------------------------------------*/
.letter_section {
	min-height: 500px;
	display: flex;
	/* align-items: center; */
	justify-content: center;
	margin-top: 180px;
}
.letter_section h2 {
	text-align: center;
	font-size: 70px;
	color: #103fa1;
	color: #39b54a;
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
		'Lucida Sans', Arial, sans-serif;
}
/*----------------------------------------*/
/*  STeps Section CSS
/*----------------------------------------*/
.after-home-about {
	background-color: #222222;
	min-height: 300px;
}
.after-home-about h1 {
	color: #222222;
	color: white;
	font-size: 60px;
}
.after-home-about h4 {
	color: #39b54a;
	font-size: 20px;
}
.after-home-about .services-cont {
	padding: 30px 0;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.after-home-about .services-cont .box {
	width: 32%;
	height: 110px;
	background-color: #39b54a;
	margin-right: 2px;
	transition: all 0.3s ease-in-out;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin: 10px 0;
}
.after-home-about .box:hover {
	background-color: white;
	box-shadow: 0 01px #39b54a, 01px 0 #39b54a, 01px 02px #39b54a, 02px 01px #39b54a,
		02px 03px #39b54a, 03px 02px #39b54a, 03px 04px #39b54a, 04px 03px #39b54a,
		04px 05px #39b54a, 05px 04px #39b54a, 05px 06px #39b54a, 06px 05px #39b54a;
}
.after-home-about .box img {
	width: 60px;
	height: 60px;
	transition: all 0.3s;
	transform: scale(0);
	position: absolute;
	left: 5px;
}
.after-home-about .box:hover img {
	transform: scale(1);
	display: inline-block;
}
.after-home-about .box h3 {
	position: absolute;
	left: 10%;
	transition: all 0.3s;
	color: white;
	height: 100%;
	margin: 0;
	display: flex;
	align-items: center;
	font-family: 'AvantGarde Bold', Sans-serif;
}
.after-home-about .box:hover h3 {
	padding-left: 40px;
	color: #39b54a;
}
@media (max-width: 1000px) {
	.after-home-about .services-cont {
		flex-wrap: wrap;
	}
	.after-home-about .services-cont .box {
		width: 48%;
		margin: 10px 2px;
	}
}
@media (max-width: 625px) {
	.after-home-about .services-cont {
		flex-direction: column;
	}
	.after-home-about .services-cont .box {
		width: 100%;
	}
}

/*----------------------------------------*/
/*  Reacing Globaly Section CSS
/*----------------------------------------*/

section.reaching-global {
	min-height: 400px;
	background-color: #222222;
}
section.reaching-global h2.text-white {
	position: relative;
	width: fit-content;
}
section.reaching-global h2.text-white::before {
	content: '';
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 5%;
	border: 2px solid #39b54a;
	background-color: #39b54a;
}
section.reaching-global ul {
	float: left;
	width: 25%;
	margin-bottom: 40px;
}
section.reaching-global ul h2 {
	color: #39b54a;
	font-size: 20px;
}

section.reaching-global ul li {
	list-style: url('../assets/icon/ul-li-img.png');
}
section.reaching-global ul li a {
	color: white;
	transition: all 0.5s;
	text-transform: uppercase;
}
section.reaching-global ul li:hover a {
	padding-left: 5px;
	color: #25d366 !important;
}
/*----------------------------------------*/
/*  Milestones Section CSS
/*----------------------------------------*/
.milestones-section {
	min-height: 400px;
	background-color: #25d366;
	padding: 70px 0;
	display: flex;
	align-items: center;
	background-size: cover;
	background-position: 100% 100%;
}
.milestones-section img {
	width: 60px;
	height: 60px;
	color: white;
}
.counter {
	padding: 20px 0;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
.counter .timer {
	color: white;
}
.count-title {
	font-size: 40px;
	font-weight: normal;
	margin-top: 10px;
	margin-bottom: 0;
	text-align: center;
}
.count-text {
	font-size: 25px;
	font-weight: normal;
	margin-top: 10px;
	margin-bottom: 0;
	text-align: center;
	color: white;
}
.fa-2x {
	margin: 0 auto;
	float: none;
	display: table;
	color: white;
}

/*--------servic-area start---------*/

.section-title {
	margin-bottom: 40px;
}
.section-title h2 {
	font-size: 50px;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 5px;
	color: #222222;
}
.section-title h2 span {
	color: #222;
	padding: 5px;
}
.section-title h3 {
	font-weight: 500;
	font-size: 20px;
	margin-bottom: 0px;
}
.spacial-img {
	position: relative;
}
.spacial-wrap {
	padding: 60px 0px 30px;
}
.spacial-item {
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
	/* padding: 49px 0px; */
	width: 180px;
	height: 200px;
	margin-bottom: 30px;
	transition: 0.5s;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.spacial-item:hover {
	background: #39b54a;
	transform: scale(1.08);
}
.spacial-item span {
	display: block;
	margin-bottom: 10px;
}
.spacial-item span:before {
	font-size: 35px;
}
.spacial-item h4 {
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 0px;
}
/*--------servic-area end---------*/

/*----------------------------------------*/
/*  Footer Section CSS
/*----------------------------------------*/

/*--------footer-area start---------*/
footer {
	font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.footer-top {
	padding: 40px 0px 40px;
}
.footer-widget {
	margin-bottom: 30px;
}
.footer-logo img {
	margin-bottom: 30px;
}
.footer-logo p {
	margin-bottom: 20px;
	color: white;
}
.footer-logo h4 {
	font-size: 16px;
	text-transform: uppercase;
	margin-bottom: 5px;
}
.footer-logo span {
	display: block;
	margin-bottom: 20px;
	color: white;
}
.footer-logo ul li {
	display: inline-block;
	margin-right: 10px;
	font-size: 18px;
	color: white;
}
.footer-widget h4.widget-title {
	font-size: 18px;
	text-transform: uppercase;
	padding-left: 30px;
	margin: 30px 0px 50px;
	font-weight: 600;
	position: relative;
	color: white;
}
.footer-widget h4.widget-title:before {
	position: absolute;
	left: 0;
	top: 5px;
	width: 5px;
	height: 12px;
	background: #39b54a;
	content: '';
}
.footer-contact ul li {
	margin-bottom: 20px;
	position: relative;
	padding-left: 40px;
	line-height: 24px;
	font-size: 14px;
	color: white;
}
.footer-contact ul li i {
	/* height: 28px;
  width: 28px; */
	padding: 4px 8px;
	font-size: 14px;
	border: 1px solid #39b54a;
	text-align: center;
	line-height: 24px;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
}
.footer-contact ul li:last-child {
	margin-bottom: 0px;
}
.footer-menu ul li {
	line-height: 37px;
	padding-left: 30px;
	position: relative;
}
.footer-menu ul li:before {
	position: absolute;
	left: 0;
	top: 0;
	content: '\f101';
	font-family: fontawesome;
}
footer a.ryk:hover {
	color: blue !important;
}
.footer-menu ul li:hover:before {
	left: 5px;
	color: #39b54a;
}
footer a {
	color: white;
}
.newsletter p {
	margin-bottom: 10px;
	color: white;
}
.newsletter input {
	width: 100%;
	height: 40px;
	border: 1px solid #e5e5e5;
	padding-left: 15px;
	background: transparent;
	margin-bottom: 10px;
	color: white;
}
.newsletter input::placeholder {
	color: white;
}
.newsletter button {
	width: 115px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	text-transform: uppercase;
	font-size: 14px;
	border: none;
	background: #39b54a;
	cursor: pointer;
	border-radius: 3px;
	color: white;
}
footer a:hover {
	color: #39b54a;
}
.newsletter button:hover {
	border: 1px solid #39b54a;
	color: #fff;
	background-color: transparent;
}
.footer-bootem {
	background: #39b54a;
	text-align: center;
}
.footer-bootem .col-12 {
	display: flex;
	justify-content: center;
}
.footer-bootem .col-12 p {
	margin-top: 20px;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}
.footer-bootem .col-12 p a {
	display: flex;
	align-items: center;
	justify-content: center;
}
.footer-bootem a:hover {
	color: white !important;
}
.footer-bootem a i {
	width: 30px;
	height: 30px;
	color: black;
	border-radius: 100%;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}
.footer-bootem a i:hover {
	background-color: #1a73e8;
	color: white;
}

/*----------------------------------------*/
/*  Other pages CSS
/*----------------------------------------*/
.page-header-section {
	height: 445px;
	padding-top: 200px;
}

.page-header-section .header-title {
	font-size: 70px;
	color: #fff;
}

.page-header-section .header-title span {
	background: none;
	color: #39b54a;
}

/*----------------------------------------*/
/*  About page CSS
/*----------------------------------------*/
.testimonials-section {
	position: relative;
	margin: 60px 0;
}

.testimonials-section h1 {
	color: #fff;
}

.testimonials-section:after {
	content: '';
	position: absolute;
	width: 67%;
	height: 100%;
	right: 0;
	top: 0;
	background: #222222;
	z-index: 2;
}

.testimonials-section .testimonials-image-box {
	position: absolute;
	width: 50%;
	height: calc(100% + 120px);
	left: 0;
	top: -60px;
	background-image: url('../assets/cta-img.jpg');
	background-repeat: no-repeat;
	background-size: cover;
}

.testimonials-section .container {
	position: relative;
	z-index: 9;
}
.ts-item {
	margin-bottom: 30px;
	display: flex;
}

.ts-item i {
	font-size: 50px;
	color: white;
	transition: 0.5s;
}
.ts-item i:hover {
	transform: scale(1.1);
}
.ts-item li {
	color: #fff;
	font-style: italic;
	margin-top: 15px;
}

.ts-item h2 {
	font-size: 25px;
	font-weight: 400;
	color: #39b54a !important;
	margin-bottom: 0;
}

.ts-item span {
	font-size: 12px;
	color: #fff;
}

.team-member {
	padding-right: 67px;
	position: relative;
}

.team-member img {
	min-width: 100%;
}

.team-member .member-info {
	position: absolute;
	padding-left: 35px;
	padding-top: 10px;
	padding-bottom: 10px;
	width: 230px;
	background: #fff;
	bottom: 37px;
	right: 0;
	-webkit-box-shadow: 6px 7px 20px rgba(114, 114, 114, 0.21);
	box-shadow: 6px 7px 20px rgba(114, 114, 114, 0.21);
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
}

.team-member .member-info h2 {
	font-size: 30px;
	margin-bottom: 0;
}

.team-member .member-info p {
	color: #222222;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 0;
}

.team-member:hover .member-info {
	background: #baff00;
	-webkit-box-shadow: 0px 0px 0px rgba(114, 114, 114, 0.21);
	box-shadow: 0px 0px 0px rgba(114, 114, 114, 0.21);
}

.promo-section {
	padding-top: 90px;
	padding-bottom: 150px;
}

.promo-text h1 {
	margin-bottom: 20px;
}

.promo-text p {
	font-size: 16px;
	font-weight: 500;
	color: #222;
	margin-bottom: 0;
}

.slide-num-holder.test-slider {
	right: auto;
	left: 100%;
	bottom: -160px;
	width: 160px;
	height: 230px;
	padding-right: 30px;
	padding-top: 80px;
}

.about-us .inner-detail-cont:nth-child(odd) {
	border-radius: 10px;
	/* background-color: #fdb914; */
	/* background-color: #A1ecbf; */
	background-color: #39b54a;
	background-color: #fcb100;
	background-color: #222222;
	margin-bottom: 10px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
		0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1),
		0 16px 16px rgba(0, 0, 0, 0.1);
}
.about-us .inner-detail-cont:nth-child(odd) .half-div {
	width: 12px;
	height: 12px;
	border-top: 12px solid #39b54a;
	border-left: 12px solid #39b54a;
	border-right: 12px solid transparent;
	border-bottom: 12px solid transparent;
	margin: 10px 0;
}
.about-us .inner-detail-cont:nth-child(odd) h3 {
	color: #39b54a;
}
.about-us .inner-detail-cont:nth-child(odd) p {
	color: white;
}

.about-us .inner-detail-cont:nth-child(even) {
	border-radius: 10px;
	/* background-color: #fdb914; */
	/* background-color: #A1ecbf; */
	background-color: #39b54a;
	/* background-color: #fcb100; */
	/* background-color: #222222; */
	margin-bottom: 10px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
		0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1),
		0 16px 16px rgba(0, 0, 0, 0.1);
}
.about-us .inner-detail-cont:nth-child(even) .half-div {
	width: 12px;
	height: 12px;
	border-top: 12px solid white;
	border-left: 12px solid white;
	border-right: 12px solid transparent;
	border-bottom: 12px solid transparent;
	margin: 10px 0;
}
.about-us .inner-detail-cont:nth-child(even) h3 {
	color: white;
}
.about-us .inner-detail-cont:nth-child(even) p {
	color: rgb(6, 26, 35);
}
.about-us .inner-detail-cont:hover {
	transform: scale(1.02);
}
.about-us .inner-detail-cont {
	margin: 50px 0;
	transition: all 0.4s;
}

/*----------------------------------------*/
/*  Service page CSS
/*----------------------------------------*/
.service-slider {
	position: relative;
}

.service-slider .owl-controls {
	position: absolute;
	height: 100%;
	width: 14px;
	left: 0;
	top: 0;
	display: -ms-grid;
	display: grid;
}

.service-slider .owl-dots {
	display: table-cell;
	vertical-align: middle;
}

.service-slider .owl-dots .owl-dot {
	width: 14px;
	height: 13px;
	margin-bottom: 10px;
	background: #e8e8e8;
}

.service-slider .owl-dots .owl-dot.active {
	background: #baff00;
}

.service-text h2 {
	font-size: 30px;
	margin-bottom: 30px;
}

.service-text p {
	margin-bottom: 50px;
}

.service-text ol {
	list-style: none;
}

.service-text ol li {
	font-size: 20px;
	font-weight: 700;
	color: #727272;
	margin-bottom: 20px;
}

.solid-service-box {
	text-align: center;
	background: #fff;
	padding: 50px 30px;
	-webkit-transition: all 0.4s ease-out 0s;
	-o-transition: all 0.4s ease-out 0s;
	transition: all 0.4s ease-out 0s;
}

.solid-service-box h2 {
	font-size: 48px;
	color: #727272;
	margin-bottom: 20px;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
}

.solid-service-box h3 {
	margin-bottom: 20px;
}

.solid-service-box p {
	font-size: 14px;
	margin-bottom: 20px;
}

.solid-service-box .readmore {
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	color: #222;
	opacity: 0;
	visibility: hidden;
	position: relative;
	bottom: -20px;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
}

.solid-service-box:hover {
	/* background: #baff00; */
	background: #25d366;
}

.solid-service-box:hover h2 {
	color: #222;
}

.solid-service-box:hover .readmore {
	visibility: visible;
	opacity: 1;
	bottom: 0;
}

.promo-box {
	width: 1383px;
	margin: 0 auto;
	padding: 40px 0;
}

.promo-box .promo-text h1,
.promo-box .promo-text p {
	color: #fff;
}

/*----------------------------------------*/
/*  Blog page CSS
/*----------------------------------------*/
.blog-post {
	margin-bottom: 120px;
}

.blog-post .thumb {
	padding: 25px;
	position: relative;
}

.blog-post .thumb:after {
	position: absolute;
	content: '';
	width: 100%;
	height: calc(100% - 100px);
	top: 0;
	left: 0;
	background: #f0f0f0;
	z-index: -1;
	-webkit-transition: all 0.4s ease-out 0s;
	-o-transition: all 0.4s ease-out 0s;
	transition: all 0.4s ease-out 0s;
}

.blog-post .post-date {
	font-size: 14px;
	font-weight: 700;
	color: #222;
	display: inline-block;
	background: #baff00;
	padding: 4px 15px;
	margin-bottom: 20px;
}

.blog-post h2 {
	font-size: 30px;
}

.blog-post h2 a {
	color: #222;
}

.blog-post p {
	margin-bottom: 0;
}

.blog-post .post-meta {
	margin-bottom: 40px;
}

.blog-post .post-meta a {
	color: #727272;
	font-size: 12px;
	margin-right: 10px;
}

.blog-post .post-meta a i {
	font-size: 16px;
	margin-left: 5px;
}

.blog-post:hover .thumb:after {
	background: #baff00;
}

.pagination {
	display: inline-block;
	padding: 20px 30px;
	background: #222;
	border-radius: 0;
}

.pagination a {
	font-size: 18px;
	font-weight: 700;
	color: #fff;
}

.pagination a.active {
	font-size: 48px;
	color: #baff00;
}

.search {
	position: relative;
}

.widget-area {
	margin-bottom: 80px;
}

.widget-area .widget-title {
	font-size: 22px;
	margin-bottom: 40px;
}

.widget-area .search input {
	width: 100%;
	background: #f0f0f0;
	border: none;
	font-size: 12px;
	padding: 10px;
	padding-right: 35px;
	font-style: italic;
}

.widget-area .search button {
	position: absolute;
	right: 0;
	top: 0;
	background: none;
	border: none;
	color: #838383;
	height: 100%;
	width: 40px;
}

.widget-area ul {
	list-style: none;
}

.widget-area ul li a {
	font-size: 15px;
	display: inline-block;
	margin-bottom: 15px;
	font-weight: 500;
	color: #727272;
	padding: 3px 10px;
	padding-left: 25px;
	position: relative;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.widget-area ul li a:after {
	position: absolute;
	content: '+';
	color: #838383;
	left: 5px;
	top: 3px;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.widget-area ul li a:hover {
	background: #baff00;
	color: #222;
}

.widget-area ul li a:hover:after {
	color: #222;
}

.widget-area ul li:last-child a {
	margin-bottom: 0;
}

.widget-area .rp-widget .rp-widget-item {
	margin-bottom: 30px;
	overflow: hidden;
}

.widget-area .rp-widget .rp-widget-item:last-child {
	margin-bottom: 0;
}

.widget-area .rp-widget .thumb {
	width: 68px;
	height: 68px;
	float: left;
	margin-right: 30px;
	background: #ddd;
	display: block;
}

.widget-area .rp-widget .rp-content {
	padding-left: 98px;
}

.widget-area .rp-widget h4 {
	line-height: 1.5;
	margin-bottom: 0;
}

.widget-area .rp-widget p {
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 0;
}

.widget-area .quote-widget span {
	font-size: 47px;
	font-style: italic;
	color: #727272;
}

.widget-area .quote-widget p {
	font-style: italic;
	margin-bottom: 0;
	font-size: 13px;
}

.widget-area .instagram-widget {
	padding-top: 30px;
}

.widget-area .instagram-widget a {
	display: block;
	overflow: hidden;
	width: 33.33333%;
	float: left;
	position: relative;
}

.widget-area .instagram-widget a:after {
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: #baff00;
	opacity: 0;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.widget-area .instagram-widget a:before {
	position: absolute;
	content: '+';
	color: #fff;
	font-weight: 500;
	text-align: center;
	font-size: 36px;
	line-height: 36px;
	width: 20px;
	top: 50%;
	margin-top: -13px;
	left: 50%;
	margin-left: -10px;
	text-shadow: 0 0 20px #999;
	opacity: 0;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	z-index: 2;
}

.widget-area .instagram-widget a:hover:after,
.widget-area .instagram-widget a:hover:before {
	opacity: 1;
}

.widget-area .instagram-widget a img {
	min-width: 100%;
}

@media screen and (min-width: 1024px) {
	.map {
		height: 500px;
	}
}
.cf-social {
	margin-top: 50px;
}

.cf-social a {
	color: #222;
	margin-right: 25px;
}

.contact-form {
	padding-top: 10px;
}

.contact-form input,
.contact-form textarea {
	width: 100%;
	font-size: 13px;
	border: none;
	background: #f0f0f0;
	padding: 15px 20px;
	margin-bottom: 20px;
}

.contact-form textarea {
	height: 200px;
	margin-bottom: 30px;
}

.contact-form ::-webkit-input-placeholder {
	font-style: italic;
}

.contact-form :-ms-input-placeholder {
	font-style: italic;
}

.contact-form ::-ms-input-placeholder {
	font-style: italic;
}

.contact-form ::placeholder {
	font-style: italic;
}

.map-area {
	height: 685px;
	width: 100%;
	display: block;
	background: #f0f0f0;
	margin-bottom: 2px;
}

.CEO-img {
	height: 420px;
}

.director-img {
	background-size: cover;
	background-position: cover;
	position: relative;
	transition: 0.5s;
}
.director-img:hover {
	transform: scale(1.05);
	box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
}

.img-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	bottom: 0%;
	left: 0;
	right: 0;
	background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.54));
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.5s;
	transform: scale(0);
}
.img-overlay h1 {
	align-self: center;
	font-size: 20px;
}
.img-overlay .social-links {
	width: 70%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.img-overlay .social-links li {
	display: flex;
	margin: 0px 5px;
}
.director-img:hover .img-overlay {
	bottom: 0;
	transform: scale(1);
}
.director-img::before {
	content: '';
	width: 4%;
	height: 4%;
	position: absolute;
	top: -1.8%;
	left: -1.5%;
	border-radius: 100%;
	transition: 0.5s;
	background-color: #39b54a;
	animation: move 5s infinite;
}
@keyframes move {
	0% {
		left: 0;
		top: -1.8%;
	}
	25% {
		left: 98.5%;
		top: -1.8%;
	}
	50% {
		top: 97.5%;
		left: 98.5%;
	}
	75% {
		left: -1.5%;
		top: 97.5%;
	}
	100% {
		top: -1.5%;
		left: -1.5%;
	}
}

.board_of_directors .intro-section {
	padding: 40px 0;
}
.board_of_directors .CEO .intro-text {
	padding: 0 40px;
}
.board_of_directors .CEO-img > div {
	position: relative;
	width: 100%;
	height: 100%;
}
.board_of_directors .CEO-img img {
	width: 100%;
	height: 100%;
	background-color: red;
}
.board_of_directors .CEO-img > div::before {
	content: '';
	position: absolute;
	right: -20px;
	top: 20px;
	border: 10px solid #39b54a;
	width: 100%;
	height: 100%;
	transition: all 0.3s ease-in-out;
}
.board_of_directors .CEO-img:hover .CEO-img::before {
	content: '';
	right: 0px;
	top: 0px;
}

.board_of_directors .intro-text h1 span.bgColor {
	padding: 0;
}
.m_our_story .CEO,
.board_of_directors .CEO {
	height: 500px;
	display: flex;
	justify-content: center;
	margin-top: 10px;
}
.m_our_story .CEO > div {
	width: 500px;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}
.board_of_directors .CEO > div {
	width: 500px;
	height: 100%;
	background-color: #39b54a;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	background-size: 90% 150%;
	background-repeat: no-repeat;
	background-position: 130px -70px;
}
.board_of_directors .CEO > div > div {
	width: 70%;
	height: 85%;
	border: 2px solid white;
	position: relative;
	margin-right: 50px;
}
.board_of_directors .CEO .content {
	width: 60%;
	height: 60%;
	border-top: 1px solid white;
	border-bottom: 1px solid white;
	position: absolute;
	top: 50%;
	background-color: #39b54a;
	transform: translate(-43%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.board_of_directors .CEO .content h4 {
	color: white;
	font-size: 35px;
}
.board_of_directors .CEO .content small {
	font-size: 20px;
	color: #151515;
}
.board_of_directors .CEO .content p {
	font-size: 16px;
}
@media (max-width: 500px) {
	.m_our_story .CEO > div {
		width: 90%;
		height: 80%;
	}
}
@media (max-width: 400px) {
	.board_of_directors .CEO {
		height: 400px;
		display: flex;
		justify-content: center;
		margin-top: 10px;
		padding: 0 15px !important;
	}

	.board_of_directors .CEO > div {
		width: 500px;
		height: 100%;
		background-color: #39b54a;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	.board_of_directors .CEO > div > div {
		display: none;
	}
}
/* Blog Page */
.blogs_container > h1,
.tranding-slider > h1 {
	padding: 20px 0;
	font-size: 35px;
}

.blogs_section .swiper-container {
	width: 100%;
	height: 380px;
	margin-left: auto;
	margin-right: auto;
}

.blogs_section .swiper-slide {
	font-size: 18px;
	display: flex;
}

.blogs_section .swiper-slide > div:nth-child(1) {
	width: 40%;
}
.blogs_section .swiper-slide > div:nth-child(1) img {
	width: 100%;
	height: 100%;
}
.blogs_section .swiper-slide > div:nth-child(2) {
	width: 60%;
	padding-left: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.blogs_section .swiper-slide .blog-content h1 {
	font-size: 40px;
	line-height: 50px;
	color: #0f1010;
}
.blogs_section .swiper-slide .blog-content a {
	color: inherit;
}

.blogs_container a {
	color: #333333;
}

.blogs_section .blog .img_link {
	overflow: hidden;
	height: 220px;
	width: 100%;
	display: inline-block;
	border-radius: 10px;
}
.blogs_section .blog .img_link:hover img {
	transform: scale(1.1);
}
.blogs_section .blog img {
	height: 220px;
	width: 100%;
	transition: all 0.4s;
}
.blogs_section .blog-content {
	padding: 20px 0;
	color: #222222;
}
.blogs_section .blog-content h2 {
	font-size: 17px;
	font-weight: 700;
	color: inherit;
}
.blogs_section .blog .blog-content h1 {
	height: 50px;
}
.blogs_section .blog .blog-content p {
	height: 70px;
}
.blogs_section .blog-content h1 {
	padding: 5px 0;
	font-size: 20px;
	line-height: 22px;
	color: inherit;
	overflow: hidden;
}
.blogs_section .blog-content p {
	padding: 5px 0;
	font-size: 17.5px;
	line-height: 22px;
	overflow: hidden;
}
.blogs_section .blog-content h2 span {
	color: #999;
}
.blogs_section .read_more {
	padding: 10px 20px;
	border: 2px solid #39b54a;
	color: #222222;
	font-size: 19px;
	transition: all 0.2s;
}
.blogs_section .read_more:hover {
	background-color: #39b54a;
	color: white;
}

/* Blog Detail */

.blog_detail_page .blog-detail {
	width: 900px;
	margin: auto;
}
.blog_detail_page img {
	width: 100%;
	height: 500px;
}
.blog_detail_page .icons .social-links li {
	display: inline-block;
	margin: 0 5px;
}

@media (max-width: 900px) {
	.blog_detail_page .blog-detail {
		width: 100vw;
		padding: 0 10px;
	}
	.blog_detail_page img {
		height: 300px;
	}
}

/*---------------------
  Contact
-----------------------*/

.contact {
	padding-bottom: 65px;
}
.contact .map-section {
	min-height: 300px;
	background-color: #f4f4f4;
}
.contact__map iframe {
	width: 100%;
	height: 450px;
}
.contact .contact-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 30px;
}
.contact i {
	color: #39b54a;
}
.contact h1 {
	font-size: 30px;
	font-weight: bolder;
	color: #39b54a;
}
.contact .contact-content h4 {
	font-size: 16px;
	font-weight: bolder;
	margin-bottom: 3px;
	margin-top: 20px;
}
.contact .contact-content p {
	font-size: 16px;
}
.contact .contact-content .inner {
	display: flex;
}
.contact .contact-content .inner > div {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	padding-right: 20px;
}
.contact .contact-content .inner > div img {
	width: 35px;
	height: 35px;
	fill: #39b54a;
}

.contact_content-section > div {
	background-color: white;
	box-shadow: 0px 7px 25px 1px rgb(0 0 0 / 8%);
	min-height: 650px;
	display: flex;
	margin-top: 65px;
}
.contact_content-section > div > div.row {
	display: flex;
	width: 100%;
}
.contact_content-section .left {
	padding-left: 80px;
	border-right: 1px solid rgba(243, 243, 243, 1);
}
.contact_content-section .left > div {
	width: 95%;
}
.contact_content-section .left,
.contact_content-section .right {
	display: flex;
	align-items: center;
}
.contact_content-section .left > div,
.contact_content-section .right > div {
	height: 100%;
	padding: 50px 0;
}
.contact_content-section input,
.contact_content-section textarea {
	width: 100%;
	padding: 10px 10px;
	border: 1px solid #8d8d8d;
}
.contact_content-section textarea {
	height: 140px;
}
.contact_content-section .left h1 {
	padding-bottom: 10px;
}
.contact_content-section .left h4 {
	color: #4c4c4c;
	font-weight: bolder;
	margin-bottom: 0;
	margin-top: 25px;
	padding-bottom: 12px;
}
.contact .btn,
.achievements_page .btn,
.blog_detail_page .btn {
	padding: 10px 15px;
	border: 3px solid #39b54a;
	background-color: transparent;
	transition: all 0.3s;
	color: #404040;
	margin-top: 40px;
	border-radius: 0;
}
.contact .btn:hover,
.achievements_page .btn:hover,
.blog_detail_page .btn:hover {
	background-color: #39b54a;
	color: white;
}

.blog_detail_page .left {
	padding-left: 0;
}

.blog_detail_page p {
	line-height: 25px;
	font-size: 16px;
	letter-spacing: 0.5px;
}
.blog_detail_page h2 {
	padding-top: 30px;
	padding-bottom: 0px;
}
.blog_detail_page span {
	padding-bottom: 10px;
	display: inline-block;
	font-size: 18px;
}
.blog_detail_page span strong {
	padding-left: 3px;
	padding-right: 10px;
}

/* Career Page */

.career_page h2 {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 50px 0px 10px 0;
	color: #39b54a;
	font-size: 26px;
}
.career_page h2::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 2px;
	background-color: #39b54a;
	margin: auto;
	z-index: 0;
}
.career_page h2 span {
	display: inline-block;
	width: fit-content;
	background-color: white;
	/* background-image: url('../assets/bgRough.jpg'); */
	z-index: 1;
	padding: 0 20px;
}

.career_page .job {
	padding-top: 20px;

	border-bottom: 1px solid #39b54a;
}
.career_page .job:last-child {
	margin-bottom: 30px;
}
.career_page .Maintitle {
	display: flex;
}
.career_page .Maintitle .title {
	width: 20%;
}
.career_page .Maintitle .detail {
	width: 80%;
	display: flex;
	justify-content: space-between;
}
.career_page .Maintitle .detail h3 {
	font-size: 18px;
	padding-top: 5px;
	margin: 0;
}
.career_page .Maintitle .detail h4 {
	padding-top: 3px;
	font-size: 20px;
}
.career_page .Maintitle .detail .career_page span {
	color: #666666;
	font-size: 20px;
	font-weight: bold;
}
.career_page span.green {
	color: #39b54a;
	font-size: 20px;
	font-weight: bold;
}

.career_page .job a {
	color: #39b54a;
	padding: 0 4px;
	font-weight: bold;
	cursor: pointer;
}
.career_page .job a:hover {
	color: #39b54a;
}
.details {
	display: flex;
	flex-direction: row;
}
.career_page .title {
	width: 20%;
}
.career_page .detail {
	width: 80%;
}
.career_page .MuiAccordionSummary-root {
	height: 30px !important;
}

.career_page .MuiAccordionSummary-root.Mui-expanded a {
	display: none;
}
.career_page .MuiPaper-elevation1 {
	box-shadow: none !important;
}
.career_page .MuiAccordionDetails-root {
	display: block;
	padding: 0 !important;
}
.career_page .MuiPaper-root {
	background-color: transparent !important;
}

.career_page .description .titles span {
	padding: 10px 0;
	color: #39b54a;
	font-size: 20px;
	font-weight: bold;
}
.career_page .description p {
	font-size: 17px;
	padding: 0;
	margin: 0;
	line-height: normal;
	padding-top: 7px;
}
.career_page .description .details .detail p {
	padding-bottom: 10px;
}
.career_page .description li {
	list-style-type: square;
	color: #727272;
}
.career_page .description .details h5 {
	padding-top: 8px;
	font-size: 17px;
}

.career_page .applyDiv {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}
.career_page button {
	padding: 5px 20px;
	background-color: #39b54a;
	color: white;
	outline: none;
	border-radius: 5px;
	border: none;
}
.career_page .cross {
	padding: 3px 10px;
	background-color: #39b54a;
	color: white;
}

.career_page .description ~ .applyDiv i {
	display: none;
}
.career_page .description.expand ~ .applyDiv i {
	display: inline-block;
}

@media (max-width: 600px) {
	.career_page .title {
		width: 100% !important;
	}
	.career_page .detail {
		width: 100% !important;
	}
	.career_page .details {
		flex-direction: column;
		margin: 30px 0 !important;
	}
	.career_page .Maintitle {
		flex-direction: column;
		margin-bottom: 40px;
	}
	.career_page .applyDiv {
		margin-top: 30px;
	}
}
.MuiAccordion-root ~ .applyDiv {
	visibility: visible;
}
.MuiAccordion-root.Mui-expanded ~ .applyDiv > i {
	visibility: visible;
}

.career_page .form_cont {
	width: 73%;
	margin: auto;
	margin-top: 60px;
	margin-bottom: 60px;
}
.career_page .form_cont h3 {
	padding: 20px 0 10px 0;
	margin: 0;
}
.career_page .form_cont label {
	color: #39b54a;
	font-size: 16px;
}
.career_page .form_cont input::placeholder,
.career_page .form_cont textarea::placeholder {
	font-size: 15px;
}
.career_page .form_cont a,
.career_page .form_cont a:hover {
	color: #39b54a;
	cursor: pointer;
	padding-left: 5px;
	font-size: 16px;
}
.career_page .form_cont button {
	margin: 10px 0;
}
.career_page .form_cont button:hover {
	background-color: #39b54a;
}
.qoute {
	width: 63%;
	margin: auto;
	position: relative;
	height: 450px;
}

.qoute .box {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	min-height: 300px;
	transition: 0.5s;
}

.qoute .box::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
	border-top: 20px solid #3c5765;
	border-left: 20px solid #3c5765;
	box-sizing: border-box;
}
.qoute .box::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
	border-bottom: 20px solid #3c5765;
	border-right: 20px solid #3c5765;
	box-sizing: border-box;
}

.qoute .box .text {
	position: absolute;
	top: 30px;
	left: -30px;
	background-color: #39b54a;
	width: calc(100% + 60px);
	min-height: calc(100% - 60px);
	transition: 0.5s;
}
.qoute .box .text > div {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	transform: translateY(-50%);
	padding: 40px 80px;
	box-sizing: border-box;
	text-align: center;
}
.qoute .box .text > div h1 {
	margin: 0 0 20px;
	padding: 0;
	color: #fff;
	font-size: 40px;
}
.qoute .box .text > div p {
	margin: 0 0 20px;
	padding: 0;
	color: #fff;
	font-size: 18px;
	line-height: normal;
}
.qoute i {
	width: 80px;
	height: 80px;
	background-color: #3c5765;
	color: white;
	font-size: 50px;
	line-height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.qoute .text i:nth-child(1) {
	position: absolute;
	top: 0;
	left: 0;
}
.qoute .text i:nth-child(2) {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 1;
}

/* newsroom */

.newsbox:first-child .box {
	margin-top: 80px;
}

.newsbox {
	width: 75%;
	margin: auto;
}

.newsbox .box {
	width: 100%;
	min-height: 220px;

	margin-bottom: 60px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 1px 1px rgb(0 0 0 / 10%), 0 2px 2px rgb(0 0 0 / 10%),
		0 4px 4px rgb(0 0 0 / 10%), 0 8px 8px rgb(0 0 0 / 10%),
		0 16px 16px rgb(0 0 0 / 10%);
	transition: all 0.4s;
}
.newsbox .box:hover {
	transform: scale(1.02);
}
.newsbox:nth-child(even) .box {
	background-color: #222222;
}
.newsbox:nth-child(odd) .box {
	background-color: #39b54a;
}
.newsbox .box .text {
	width: 80%;
	text-align: center;
}

.newsbox .box .text h1 {
	margin: 0 0 20px;
	padding: 0;
	color: #fff;
	font-size: 40px;
}
.newsbox .box .text p {
	margin: 0 0 20px;
	padding: 0;
	color: #fff;
	font-size: 18px;
	line-height: normal;
}
.newsbox i {
	width: 80px;
	height: 80px;
	background-color: #222222;
	color: #39b54a;
	font-size: 50px;
	line-height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.newsbox i:nth-child(1) {
	position: absolute;
	top: 0;
	left: 0;
}
.newsbox i:nth-child(2) {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 1;
}
.newsbox:nth-child(even) .text h1 {
	color: #39b54a;
}
.newsbox:nth-child(even) i {
	background-color: #39b54a;
	color: #222222;
}
.newsbox:nth-child(odd) .text h1 {
	color: white;
}
/* newsroom end */

/* images */
.images .slider:first-child {
	margin-top: 80px;
}
.images .slider {
	margin: auto;
	width: 80%;
	min-height: 500px;
	display: flex;
	box-shadow: 0 1px 1px rgb(0 0 0 / 10%), 0 2px 2px rgb(0 0 0 / 10%),
		0 4px 4px rgb(0 0 0 / 10%), 0 8px 8px rgb(0 0 0 / 10%),
		0 16px 16px rgb(0 0 0 / 10%);
	margin-bottom: 70px;
}

.images .slider .text {
	width: 35%;
	height: 500px;
	overflow-y: scroll;
	padding: 22px 15px;
	background-color: #404040;
}
.images .slider .text p {
	color: white;
}
.images .slider .text {
	word-wrap: break-word;
	text-align: left;
}
.images .slider .text::-webkit-scrollbar {
	display: none;
}
.images .slider > div:nth-child(2) {
	width: 65%;
}
.images .swiper-container {
	width: 100%;
	height: 100%;
}

.images .swiper-slide {
	width: 100%;
	height: 100%;
	text-align: center;
	font-size: 18px;
	background: #fff;

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	background-position: center;
	background-size: cover;
}

/* imges end */

@media (max-width: 500px) {
	.images .slider {
		margin: auto;
		width: 100%;
		min-height: 550px;
		display: flex;
		flex-direction: column;
		box-shadow: none;
	}
	.images .slider .text {
		width: 100%;
		height: 200px;
	}
	.images .slider > div:nth-child(2) {
		width: 100%;
		height: 300px;
	}

	.career_page .form_cont {
		width: 100%;
	}
	.qoute .box .text > div h1 {
		font-size: 30px;
	}
	.career_page .form_cont input {
		width: 100%;
	}
	.qoute .box .text > div {
		padding: 0 10px;
	}
	.qoute .box::after {
		display: none;
	}
	.qoute .box::before {
		display: none;
	}
	.qoute .text > i:nth-child(1) {
		top: -33.5%;
	}
	.qoute .text > i:nth-child(2) {
		bottom: -33.5%;
	}
	.newsbox {
		width: 100%;
	}
	.newsbox i {
		width: 50px;
		height: 50px;
		background-color: #222222;
		color: #39b54a;
		font-size: 30px;
		line-height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

@media (max-width: 400px) {
	.newsbox .box {
		/* padding: 45px 0; */
		padding-top: 50px;
	}
	.newsbox .box .text h1 {
		font-size: 25px;
	}
	.newsbox i {
		width: 50px;
		height: 50px;
		background-color: #222222;
		color: #39b54a;
		font-size: 30px;
		line-height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

@media (max-width: 800px) {
	.qoute {
		width: 85%;
	}
	.newsbox i {
		width: 50px;
		height: 50px;
		background-color: #222222;
		color: #39b54a;
		font-size: 30px;
		line-height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
/* ACHIEEMNET PAGE */
.achievements_page .inner-pg-content:nth-child(even) .infra-img img {
	position: absolute;
	left: 50%;
	transform: translate(-30%, 0%) !important;
	/* top: 50%; */
}
.achievements_page .inner-pg-content:nth-child(even) .row {
	flex-direction: row-reverse !important;
}
.achievements_page .inner-pg-content .infra-img img {
	border: 12px solid #eeeeee;
	border-radius: 50%;
	height: 100%;
}
.achievements_page .inner-pg-content:nth-child(even) .line-con {
	height: 200px;
	background-image: url('../assets/oddline.png');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}
.achievements_page .inner-pg-content:nth-child(odd):not(:first-child) .line-con {
	height: 210px;
	background-image: url('../assets/evenline.png');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.achievements_page .infra-content h2 {
	font-size: 20px !important;
	color: #7f7f7f;
	padding-top: 30px;
	letter-spacing: 1px;
}
.achievements_page .infra-content p {
	font-size: 15px;
	line-height: 22px;
}
.achievements_page .infra-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.achievements_page .infra-content p span {
	display: block;
	padding: 3px;
}
@media (max-width: 900px) {
	.achievements_page .inner-pg-content:nth-child(even) .infra-img img {
		transform: translateX(0%);
	}
}

@media (max-width: 900px) {
	.board_of_directors .director-img {
		height: 500px;
		background-position: center;
		background-size: 100% 100%;
	}
}

@media (max-width: 550px) {
	.board_of_directors .director-img {
		margin: 0 15px;
		height: 300px;
	}
	.board_of_directors .intro-text {
		margin-top: 10px;
	}
	.board_of_directors .intro-text h1 {
		font-size: 31px;
	}
	.board_of_directors .intro-text h1 span.bgColor {
		font-size: 31px;
		padding: 0;
	}
}

/* ===========================
  Responsive
==============================*/
@media only screen and (max-width: 1477px) {
	.slide-num-holder.test-slider {
		left: auto;
		right: 0;
	}
}

@media only screen and (max-width: 1400px) {
	.promo-box {
		width: 100%;
	}
}

/* Medium screen : 992px. */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slide-content {
		margin-left: 220px;
	}
	.milestone p {
		margin-left: 10px;
		font-size: 17px;
	}
	.milestone h2 {
		font-size: 55px;
	}
	.milestone:after {
		width: 90px;
		height: 90px;
	}
	.slide-num-holder {
		right: 20px;
	}
	.hero-right-text {
		right: 100px;
	}
}

@media (min-width: 992px) {
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
}

/* Tablet :768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.logo-area {
		padding: 20px 40px 30px;
	}
	.dropdown-menu {
		position: absolute !important;
	}

	.slide-content {
		margin-left: 150px;
		margin-bottom: 340px;
		padding-left: 120px;
		padding-top: 120px;
		padding-bottom: 30px;
	}
	.slide-content h2 {
		font-size: 60px;
		line-height: 60px;
	}
	.slide-content:after {
		border-top: 100px solid #39b54a;
		border-left: 100px solid #39b54a;
		border-bottom: 60px solid #39b54a;
	}
	.hero-slider .owl-nav {
		left: 170px;
	}
	.slide-num-holder {
		right: 20px;
	}
	.hero-right-text {
		bottom: 70%;
		right: 100px;
	}

	.solid-service-box {
		margin-bottom: 30px;
	}
	.cta-section {
		background: #222;
		margin-bottom: 0;
	}

	.cta-image-box,
	.cta-section:after {
		display: none;
	}
	.hero-section,
	.hero-slider .hero-slide-item {
		height: 850px;
	}
	.milestone,
	.solid-service-box {
		margin-bottom: 30px;
	}
	.footer-section .footer-social {
		width: auto;
		top: -25px;
		right: 50%;
		padding: 10px;
		margin-right: -133px;
	}

	.footer-section .social-links a {
		display: inline-block;
		padding: 0 15px;
	}

	.footer-section .copyright {
		position: relative;
		width: 100%;
		max-width: 720px;
		margin: 40px auto 0;
		left: 0;
		top: 0;
		margin-bottom: -50px;
		padding-left: 15px;
	}
}

/* Large Mobile :480px. */
@media only screen and (max-width: 767px) {
	.hero-slider {
		margin-top: 90px;
	}
	.navbar {
		background-color: #121212 !important;
	}
	.dropdown-menu {
		position: absolute !important;
	}
	h1,
	.sp-title {
		font-size: 45px;
	}
	.page-header-section {
		height: 350px;
	}
	.achievements_page .infra-content {
		text-align: center;
	}
	.achievements_page .infra-img {
		text-align: center;
	}
	.achievements_page .infra-img img {
		width: 43%;
	}
	.achievements_page .line-con {
		display: none;
	}
	.achievements_page .inner-pg-content {
		padding-bottom: 40px;
	}

	.page-header-section .header-title {
		font-size: 35px;
	}
	.left-bar {
		width: 70px;
	}
	.slide-content:after,
	.hero-right-text {
		display: none;
	}

	/* .hero-section {
    height: auto;
  } */
	.hero-slider .hero-slide-item {
		padding: 60px 0;
		height: auto;
	}
	.slide-content h2 {
		font-size: 60px;
		line-height: 60px;
	}
	.hero-slider .owl-nav {
		padding-left: 70px;
		width: 100%;
		text-align: center;
		left: 0;
	}
	.slide-content {
		margin-left: 70px;
		padding: 0;
		text-align: center;
	}
	.slide-num-holder,
	.slide-num-holder.test-slider {
		right: 0;
		height: 75px;
		width: 136px;
		padding-top: 18px;
	}
	.slide-num-holder span,
	.slide-num-holder.test-slider span {
		font-size: 40px;
	}
	.slide-num-holder.test-slider {
		bottom: -135px;
	}
	.cta-section {
		background: #222;
		margin-bottom: 0;
	}
	.cta-image-box,
	.cta-section:after {
		display: none;
	}
	.milestone,
	.solid-service-box {
		margin-bottom: 30px;
	}

	.footer-section .footer-social {
		width: auto;
		height: 230px;
		top: -25px;
		right: 50%;
		padding: 10px;
		margin-right: -133px;
	}
	.footer-section .social-links a {
		display: inline-block;
		padding: 0 15px;
		margin-bottom: 15px;
	}
	.footer-section .copyright {
		position: relative;
		width: 100%;
		max-width: 720px;
		margin: 40px auto 0;
		left: 0;
		top: 0;
		margin-bottom: -50px;
		padding-left: 15px;
		text-align: center;
	}
	.footer-item {
		margin-bottom: 40px;
	}
}

/* small mobile :320px. */
@media only screen and (max-width: 800px) {
	.logo-area {
		padding: 20px 30px 30px;
	}
	.header-area {
		background: #121212;
		padding-right: 66px;
	}
	.hero-section {
		height: 500px;
	}
	.left-bar {
		display: none;
	}
	.hero-slider .owl-nav {
		padding-left: 0;
	}

	.slide-content h2 {
		font-size: 35px;
		line-height: 1.5;
	}
}

@media (min-width: 0) and (max-width: 980px) {
	.navbar-toggler {
		background-color: white !important;
		margin-right: 10px;
	}
	.counter {
		margin: 5px 0px 5px 0px;
	}
	.navbar-brand {
		justify-content: flex-start;
	}
	.navbar-brand img {
		margin-left: 10px !important;
	}
	.navbar,
	.navbar-collapse {
		background-color: #121212 !important;
	}
	.navbar-collapse li {
		padding: 10px;
		margin-left: 10px;
	}
	.contact .contact_content-section .right {
		align-items: flex-start;
	}
	.contact .contact_content-section .left {
		padding-left: 30px;
	}
}
@media (min-width: 0px) and (max-width: 330px) {
	.navbar-brand {
		justify-content: flex-start;
		padding: 0 !important;
	}
	.navbar-toggler {
		padding: 0;
	}
	.logo-area {
		width: 200px;
	}
}
@media (min-width: 0px) and (max-width: 400px) {
	.footer-bootem .col-12 p:nth-child(1) {
		display: flex;
		flex-direction: column-reverse;
	}
}

@media (max-width: 600px) {
	.hero-section {
		height: 300px;
	}
	.slide-content {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.pt100 {
		padding-top: 50px;
	}
	h2,
	.sp-title {
		margin-bottom: 20px;
		font-size: 30px !important;
	}
	.title h1 {
		padding: 0 !important;
	}
	.m_our_story .title > p {
		padding: 0 20px !important;
	}
	.letter_section h2 {
		font-size: 50px !important;
	}
	.after-home-about h1 {
		font-size: 43px;
	}
	.after-home-about h4 {
		font-size: 20px;
	}
	.services-cont h3 {
		font-size: 18px;
	}
	.reaching-global h2 {
		font-size: 24px !important;
	}
	.reaching-global ul {
		padding: 0 13px;
	}
	.reaching-global ul h2 {
		font-size: 14px !important;
	}
	.reaching-global .row > div {
		/* padding: 0 10px; */
		display: flex;
		flex-direction: column;
	}
	.footer-bootem .col-12 {
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	.footer-bootem .col-12 p {
		margin-bottom: 0;
		margin-top: 10px;
	}
	.footer-bootem .col-12 p:nth-child(2) {
		margin-bottom: 10px;
	}
}

/* Counter */

.counter-up {
	min-height: 50vh;
	padding: 0 50px;
	position: relative;
	display: flex;
	align-items: center;
}
.counter-up::before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.8);
}
.counter-up .content {
	z-index: 1;
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}
.counter-up .content .box {
	border: 1px dashed rgba(255, 255, 255, 0.6);
	width: calc(25% - 30px);
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: column;
	padding: 20px;
}
.content .box .icon {
	font-size: 48px;
	color: #e6e6e6;
}
.content .box .counter {
	font-size: 50px;
	font-weight: 500;
	color: #f2f2f2;
	font-family: sans-serif;
}
.content .box .text {
	font-weight: 400;
	color: #ccc;
}
@media screen and (max-width: 1036px) {
	.counter-up {
		padding: 50px 50px 0 50px;
	}
	.counter-up .content .box {
		width: calc(50% - 30px);
		margin-bottom: 50px;
	}
}
@media screen and (max-width: 580px) {
	.counter-up .content .box {
		width: 100%;
	}
	.counter {
		margin: 10px 0;
	}
	.letter_section {
		margin-top: 80px;
	}
	.letter_section h2 {
		font-size: 50px;
	}
}
@media screen and (max-width: 500px) {
	.counter-up {
		padding: 30px 20px 0 20px;
	}
}

footer {
	/* position: fixed; */
	bottom: 0px;
	width: 100%;
	background: #222222;
	color: white;
}
footer ..blog_page-content {
	display: flex;
}
footer ..blog_page-content .box {
	flex-basis: 50%;
	padding: 10px 20px;
}
footer .box h2 {
	font-size: 1.125rem;
	font-weight: 600;
	text-transform: uppercase;
}
footer .box .content {
	margin: 20px 0 0 0;
	position: relative;
}
footer .box .content:before {
	position: absolute;
	content: '';
	top: -10px;
	height: 2px;
	width: 100%;
	background: #1a1a1a;
}
footer .box .content:after {
	position: absolute;
	content: '';
	height: 2px;
	width: 15%;
	background: #25d366;
	top: -10px;
}
footer .left .content p {
	text-align: justify;
	color: white;
}
footer .social-links li {
	padding: 0px 5px !important;
}
footer .center .content .text {
	font-size: 1.0625rem;
	font-weight: 500;
	padding-left: 10px;
}
footer .center .content .phone {
	margin: 15px 0;
}
footer .right form .text {
	font-size: 1.0625rem;
	margin-bottom: 2px;
	color: #656565;
	color: white;
}
footer .right form .msg {
	margin-top: 10px;
}
footer .right form input,
footer .right form textarea {
	width: 100%;
	font-size: 1.0625rem;
	background: #151515;
	padding-left: 10px;
	border: 1px solid #222222;
	color: white;
}
footer .right form input:focus,
footer .right form textarea:focus {
	outline-color: #3498db;
}
footer .right form input {
	height: 35px;
}
footer .right form .btn {
	margin-top: 10px;
	padding: 0;
}
footer .right form .btn button {
	padding: 10px 50px;
	border: none;
	outline: none;
	font-size: 1.0625rem;
	font-weight: 500;
	border: 1px solid #25d366;
	background: transparent;
	cursor: pointer;
	color: white;
}
footer .right form .btn button:hover {
	background: #25d366;
}
footer .bottom center {
	padding: 5px;
	font-size: 0.9375rem;
	background: #151515;
}
footer .bottom center span {
	color: white;
}
footer .bottom center a {
	color: #25d366;
	text-decoration: none;
}
footer .bottom center a:hover {
	text-decoration: underline;
}
@media screen and (max-width: 900px) {
	footer {
		position: relative;
		bottom: 0px;
	}
	..blog_page-content {
		flex-wrap: wrap;
		flex-direction: column;
	}
	.blog_page-content .box {
		margin: 5px 0;
	}
}
@media screen and (max-width: 650px) {
	.sub_dropdown-menu {
		left: 0;
		z-index: 3;
	}
}
/* OUR STORY */
.m_our_story {
	min-height: 100vh;
	padding-top: 170px;
}

.m_our_story .title > p,
.m_our_story .title > h1 {
	text-align: center;
}
.m_our_story .title > h1 {
	font-size: 50px;
	color: #39b54a;
}
.m_our_story .title > p {
	padding: 20px 90px;
	font-size: 21px;
	line-height: 26px;
	text-align: justify;
	color: #323232;
}
.m_our_story .sideBar_toggler {
	display: none;
}
.m_our_story .sideBar_toggler {
	width: 20px;
	height: 200px;
	background-color: red;
	position: fixed;
	top: 40%;
	left: 10%;
}
.m_our_story .sideBar {
	height: 30vh;
	width: 46px;
	position: fixed;
	right: 0%;
	top: 55%;
	background-color: #39b54a;
	transform: translate(0%, -50%);
	box-shadow: 0 1px 10px 0 gainsboro;
	transition: all 0.3s;
	/* display: none; */
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}
.m_our_story .sideBar:hover {
	width: 205px;
}
.m_our_story .sideBar:hover a {
	visibility: visible;
}
.m_our_story .sideBar > div {
	width: 100%;
	height: 18%;
	position: relative;
}
.m_our_story .sideBar > div:hover {
	background-color: #0f1010;
}
.m_our_story .sideBar a {
	visibility: hidden;
	margin-right: 25px;
	position: absolute;
	right: 15px;
	transform: translate(0%, -50%);
	top: 50%;
	color: white;
}
.m_our_story .sideBar .circle {
	width: 13px;
	height: 13px;
	position: absolute;
	right: 15px;
	transform: translate(0%, -50%);
	top: 50%;
	/* background-color: rgba(57, 181, 74, 0.4); */
	background-color: white;
	background-color: #e7eefb;
	border-radius: 50%;
	border: 1px solid yellow;
}

.activeStory + .circle {
	width: 16px !important;
	height: 16px !important;
	right: 13px !important;
	background-color: white !important;
}
.m_our_story .historyBoxs {
	padding: 100px 0 0 0;
	min-height: fit-content;
}
.m_our_story .historyBoxs .timeLineBar {
	width: 25px;
	height: 60px;
	background-color: #39b54a;
	position: relative;
	margin-top: 25px;
}
.m_our_story .timeLineBar::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	transform: translate(-40%, -100%);
	width: 20px;
	height: 20px;
	border: 5px solid #39b54a;
	border-radius: 50%;
}

.historyBox > div:nth-child(1) {
	display: flex;
}
.historyBox > div:nth-child(1) > div:nth-child(2) {
	padding-left: 20px;
}
.historyBox > div:nth-child(1) h3,
.historyBox > div:nth-child(1) h2 {
	font-size: 34px;
}
.historyBox > div:nth-child(1) p {
	font-size: 18px;
	line-height: 22px;
}
.historyBox > div:nth-child(1) h2 {
	color: #39b54a;
}
.historyBox > div:nth-child(1) h3 {
	color: #323232;
}
.historyBox img {
	width: 100%;
}
/* ANimation Section */
.m_our_story .blog_page-timeline {
	min-height: 100vh;
	margin: 80px 0;
}

.m_our_story .blog_page-timeline .container {
	display: flex;
	flex-direction: column;
}
.m_our_story .timeline {
	display: flex;
	justify-content: space-between;
}
.m_our_story .timeline .left {
	width: 70%;
	display: flex;
	align-items: center;
}
.m_our_story .timeline .left > div:nth-child(1) {
	width: 85%;
	display: flex;
	align-items: center;
	position: relative;
	justify-content: flex-end;
}

.m_our_story .timeline .left > div:nth-child(2) {
	width: 15%;
	min-height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.m_our_story .timeline .circle {
	width: 200px;
	height: 200px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.m_our_story .timeline .left img {
	width: 145px;
	height: 145px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%) rotate(-90deg);
}
.m_our_story .timeline .animatedBar {
	width: 0px;
	height: 15px;
}
.m_our_story .timeline .animatedBarSm {
	display: none;
}
.m_our_story .timeline:nth-child(1) .animatedBar.start {
	animation: animateBar 0.5s linear forwards 1s;
	background-color: #fcb040;
}
.m_our_story .timeline:nth-child(2) .animatedBar.start {
	animation: animateBar 0.5s linear forwards 1.5s;
	background-color: #74d1f5;
}
.m_our_story .timeline:nth-child(3) .animatedBar.start {
	animation: animateBar 0.5s linear forwards 2.5s;
	background-color: #729898;
}
.m_our_story .timeline:nth-child(4) .animatedBar.start {
	animation: animateBar 0.5s linear forwards 3.5s;
	background-color: #aabd3c;
}
.m_our_story .timeline:nth-child(5) .animatedBar.start {
	animation: animateBar 0.5s linear forwards 4.5s;
	background-color: #fcb040;
}
.m_our_story .timeline:nth-child(6) .animatedBar.start {
	animation: animateBar 0.5s linear forwards 5.5s;
	background-color: #74d1f5;
}
.m_our_story .timeline:nth-child(7) .animatedBar.start {
	animation: animateBar 0.5s linear forwards 6.5s;
	background-color: #729898;
}
.m_our_story .timeline:nth-child(8) .animatedBar.start {
	animation: animateBar 0.5s linear forwards 7.5s;
	background-color: #aabd3c;
}
@keyframes animateBar {
	100% {
		width: 100px;
	}
}
@keyframes animatedBarSm {
	100% {
		height: 110px;
	}
}
.m_our_story .timeline .right {
	width: 30%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 10px;
	visibility: hidden;
}
.m_our_story .timeline:nth-child(even) .right h2,
.m_our_story .timeline:nth-child(even) .right p {
	text-align: end;
}
.m_our_story .timeline:nth-child(1) .right.show {
	animation: showDetail 0.5s linear 1.5s forwards;
}
.m_our_story .timeline:nth-child(2) .right.show {
	animation: showDetail 0.5s linear 2s forwards;
}
.m_our_story .timeline:nth-child(3) .right.show {
	animation: showDetail 0.5s linear 3s forwards;
}
.m_our_story .timeline:nth-child(4) .right.show {
	animation: showDetail 0.5s linear 4s forwards;
}
.m_our_story .timeline:nth-child(5) .right.show {
	animation: showDetail 0.5s linear 5s forwards;
}
.m_our_story .timeline:nth-child(6) .right.show {
	animation: showDetail 0.5s linear 6s forwards;
}
.m_our_story .timeline:nth-child(7) .right.show {
	animation: showDetail 0.5s linear 7s forwards;
}
.m_our_story .timeline:nth-child(8) .right.show {
	animation: showDetail 0.5s linear 8s forwards;
}
@keyframes showDetail {
	100% {
		visibility: visible;
	}
}
.m_our_story .timeline .right h2 {
	font-size: 20px;
	padding-top: 40px;
	color: #212529;
}
.m_our_story .timeline .right p {
	font-size: 16px;
	line-height: 24px;
	color: #212529;
}
.m_our_story .timeline:nth-child(odd):not(:first-child) {
	margin-top: -12px;
	margin-left: -6px;
}

.m_our_story .timeline:nth-child(odd) .circle {
	transform: rotate(90deg);
}
.m_our_story .timeline:nth-child(even) .circle {
	transform: rotate(0deg);
}

/* EVEN */
.m_our_story .timeline:nth-child(even) {
	margin-top: -10px;
}
.m_our_story .timeline:nth-child(2) {
	margin-top: -13px;
}
.m_our_story .timeline:nth-child(even) .left > div:nth-child(2) {
	width: 85%;
	justify-content: flex-start;
}
.m_our_story .timeline:nth-child(even) .left > div:nth-child(1) {
	width: 15%;
	height: 100%;

	justify-content: flex-end;
}
.m_our_story .timeline:nth-child(even) .circle::after {
	content: '';
	position: absolute;
	width: 30px;
	height: 30px;
	left: 46%;
	top: -9px;
	transform: translate(-50%, 0);
	border-radius: 50%;
	border: 5px solid #fcb040;
}
.m_our_story .timeline:nth-child(even) .circle::before {
	content: '';
	position: absolute;
	width: 30px;
	height: 30px;
	left: 48%;
	bottom: -8px;
	border-radius: 50%;
	border: 5px solid #fcb040;
	transform: translate(-50%, 0);
}
.m_our_story .timeline:nth-child(even) img {
	transform: translate(-50%, -50%) rotate(0deg);
}

@media (max-width: 550px) {
	.m_our_story .title > h1 {
		font-size: 26px;
	}
	.m_our_story .title > p {
		font-size: 16px;
	}
	.historyBox > div:nth-child(1) h3,
	.historyBox > div:nth-child(1) h2 {
		font-size: 26px;
	}
	.historyBox > div:nth-child(1) p {
		font-size: 186x;
	}
}
@media (max-width: 800px) {
	.m_our_story .sideBar {
		right: -20%;
	}
	.m_our_story .sideBar_toggler {
		width: 500px;
		height: 200px;
		background-color: red;
		position: fixed;
		top: 20%;
		right: 10%;
	}
}

@media (max-width: 600px) {
	.m_our_story .timeline {
		flex-direction: column;
	}
	.m_our_story .timeline:nth-child(even) {
		flex-direction: column-reverse;
	}
	.m_our_story .timeline:nth-child(even) .circle::before,
	.m_our_story .timeline:nth-child(even) .circle::after {
		display: none;
	}
	.m_our_story .timeline .left {
		width: 100%;
		flex-direction: column;
	}
	.m_our_story .timeline:nth-child(even) .circle {
		transform: rotate(-90deg) !important;
	}

	.m_our_story .timeline .left > div {
		flex-direction: column;
	}
	.m_our_story .timeline .left > div:nth-child(2) {
		min-height: 120px;
	}
	.m_our_story .timeline:nth-child(even) .left > div:nth-child(1) {
		/* min-height: 120px; */
	}
	.m_our_story .timeline:nth-child(odd) .circle {
		transform: rotate(180deg);
	}
	.m_our_story .timeline:nth-child(even) .left > div:nth-child(1) {
		order: 1;
	}
	.m_our_story .timeline:nth-child(even) .left > div:nth-child(2) {
		order: 0;
	}
	.m_our_story .timeline:nth-child(even) .left > div:nth-child(2) {
		flex-direction: column-reverse;
	}
	.m_our_story .timeline .circle img {
		transform: translate(-50%, -50%) rotate(180deg);
	}
	.m_our_story .timeline:nth-child(even) img {
		transform: translate(-50%, -50%) rotate(90deg);
	}
	.m_our_story .timeline .animatedBar {
		display: none !important;
	}
	.m_our_story .timeline .animatedBarSm {
		display: block;
		width: 15px;
		height: 0px;
		transform-origin: bottom bottom;
		background-color: green;
	}
	.m_our_story .timeline:nth-child(1) .animatedBarSm.start {
		animation: animatedBarSm 0.5s linear forwards 1s;
		background-color: #fcb040;
	}
	.m_our_story .timeline:nth-child(2) .animatedBarSm.start {
		animation: animatedBarSm 0.5s linear forwards 1.5s;
		background-color: #74d1f5;
	}
	.m_our_story .timeline:nth-child(3) .animatedBarSm.start {
		animation: animatedBarSm 0.5s linear forwards 2.5s;
		background-color: #729898;
	}
	.m_our_story .timeline:nth-child(4) .animatedBarSm.start {
		animation: animatedBarSm 0.5s linear forwards 3.5s;
		background-color: #aabd3c;
	}
	.m_our_story .timeline:nth-child(5) .animatedBarSm.start {
		animation: animatedBarSm 0.5s linear forwards 4.5s;
		background-color: #aabd3c;
	}
	.m_our_story .timeline:nth-child(6) .animatedBarSm.start {
		animation: animatedBarSm 0.5s linear forwards 5.5s;
		background-color: #aabd3c;
	}
	.m_our_story .timeline:nth-child(7) .animatedBarSm.start {
		animation: animatedBarSm 0.5s linear forwards 6.5s;
		background-color: #aabd3c;
	}
	.m_our_story .timeline:nth-child(8) .animatedBarSm.start {
		animation: animatedBarSm 0.5s linear forwards 7.5s;
		background-color: #aabd3c;
	}
	.m_our_story .timeline .right {
		width: 100%;
		padding-top: 10px;
	}
	.m_our_story .timeline .right h2 {
		text-align: center;
		padding: 0px;
		font-size: 23px;
	}
	.m_our_story .timeline .right p {
		text-align: center;
		font-size: 15px;
	}
	.m_our_story .timeline:nth-child(even) .right h2 {
		text-align: center;
	}
	.m_our_story .timeline:nth-child(even) .right p {
		text-align: center;
	}
}
@media (max-width: 1100px) {
	.blogs_section .swiper-container {
		height: 900px;
	}
}
@media (max-width: 900px) {
	.blogs_section .swiper-container {
		height: 750px;
	}
}

@media (max-width: 600px) {
	.blogs_section .swiper-container {
		height: 650px;
	}
}

@media (max-width: 1100px) {
	.blogs_section .swiper-slide {
		flex-direction: column !important;
	}
	.blogs_section .swiper-slide > div:nth-child(1),
	.blogs_section .swiper-slide > div:nth-child(2) {
		width: 100%;
		padding-left: 0px;
	}

	.blog-content h2 {
		font-size: 15px !important;
	}
	.blog-content h1 {
		font-size: 32px !important;
		line-height: 35px !important;
	}
	.blog-content p {
		font-size: 15.5px !important;
		line-height: 22px;
	}
	.blogs_section .blog-content h2 span {
		color: #999;
	}
}
