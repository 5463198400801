.loader_screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9000000;
  background-color: #39b54a;
  position: fixed;
  /* opacity: 0.6; */
  background: linear-gradient(rgba(57, 181, 74, 0.8), rgba(57, 181, 74, 0.8));
}
